@import '~antd/lib/style/themes/default.less';
.container {
  margin-left: 20px;
  ul {
    list-style: none;
  }
  > ul {
    margin: 0;
    > li {
      line-height: 32px;

      position: relative;

      display: inline-block;
      &.active,
      &:hover {
        > a {
          font-weight: bold;

          opacity: 1;
        }
        > .sub {
          display: block;
        }
      }
      > a {
        font-size: @font-size-base;

        display: inline-block;

        padding: 0 12px;

        cursor: pointer;

        opacity: .65;
        color: #fff;
      }
    }
  }
}

.sub {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 50%;

  display: none;

  width: 160px;
  margin-left: -80px;
  padding-top: 24px;
  > ul {
    width: 100%;
    padding: 12px;

    border-radius: @border-radius-base;
    background-color: @primary-color;
    box-shadow: @box-shadow-base;
    > li {
      line-height: 32px;

      position: relative;
      .sub {
        top: -24px;
        left: 136px !important;

        margin-left: 0;
      }
      &:hover {
        > a {
          font-weight: bold;

          opacity: 1;
        }
        > .sub {
          display: block;
        }
      }
      > a {
        font-size: @font-size-base;

        display: inline-block;

        width: 100%;

        cursor: pointer;

        opacity: .65;
        color: #fff;
        &:hover {
          font-weight: bold;

          opacity: 1;
        }
      }
    }
  }
}
a.active {
  font-weight: bold !important;

  opacity: 1 !important;
}

@link-color: #3370FF;@primary-color: #0039BF;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@grey-1: #fafafa;@grey-2: #f5f5f5;@grey-3: #f0f0f0;@grey-4: #d9d9d9;@grey-5: #bfbfbf;@grey-6: #8c8c8c;@grey-7: #595959;@grey-8: #434343;@grey-9: #262626;@grey-10: #1f1f1f;@border-radius-base: 4px;@border-color-base: #e9e9e9;@text-color: #646873;@text-color-secondary: #8F949E;