@import '~antd/lib/style/themes/default.less';
.container {
  min-width: 1280px;
  min-height: 100vh;
  padding-top: 64px;

  background-color: #f0f2f5;
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}
.pages {
  position: relative;
  z-index: 1;
}

@link-color: #3370FF;@primary-color: #0039BF;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@grey-1: #fafafa;@grey-2: #f5f5f5;@grey-3: #f0f0f0;@grey-4: #d9d9d9;@grey-5: #bfbfbf;@grey-6: #8c8c8c;@grey-7: #595959;@grey-8: #434343;@grey-9: #262626;@grey-10: #1f1f1f;@border-radius-base: 4px;@border-color-base: #e9e9e9;@text-color: #646873;@text-color-secondary: #8F949E;