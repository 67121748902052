@import '~antd/lib/style/themes/default.less';
.container {
  width: 370px;
  margin-bottom: 108px;
  padding: 24px 0;
}
.logo-line {
  text-align: center;
}
.toggle-line {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

  height: 48px;
  margin: 24px 0;
}
.toggle-btn {
  font-size: 16px;

  height: 48px;
  padding: 0 20px;

  cursor: pointer;

  color: @text-color;
  border: 0;
  border-bottom: 2px solid #fff;
  background-color: #fff;
  &:hover {
    color: @primary-color;
    border-color: @primary-color;
  }
  &.active {
    color: @primary-color;
    border-color: @primary-color;
  }
}
.check-line {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;
  margin-bottom: 24px;
}
.link {
  color: @text-color-secondary;
  &:hover,
  &:active {
    color: @link-color;
  }
}
.link-line {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  height: 32px;
  margin-top: 28px;
  > .link {
    padding: 0 24px;
  }
}
.power-line {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  height: 32px;
}
.power-label {
  margin-right: 12px;

  color: @text-color-secondary;
}
.submit {
  margin-top: 12px;
}

@link-color: #3370FF;@primary-color: #0039BF;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@grey-1: #fafafa;@grey-2: #f5f5f5;@grey-3: #f0f0f0;@grey-4: #d9d9d9;@grey-5: #bfbfbf;@grey-6: #8c8c8c;@grey-7: #595959;@grey-8: #434343;@grey-9: #262626;@grey-10: #1f1f1f;@border-radius-base: 4px;@border-color-base: #e9e9e9;@text-color: #646873;@text-color-secondary: #8F949E;