html {
  font-size: 20px;
}
// antd
.ant-anchor-wrapper {
  margin-left: -7px;
  padding-left: 7px;
}
.ant-anchor-ink-ball {
  width: 14px;
  height: 14px;
  margin-top: -3px;

  border-width: 4px;
}

.scroller-vertical-track {
  width: 10px;

  transition: opacity .3s;

  background-color: transparent;
}

.scroller-horizontal-track {
  height: 10px;

  transition: opacity .3s;

  background-color: transparent;
}

.scroller-vertical-handler {
  right: 1px;

  width: 8px;

  transition: opacity .3s;

  border-radius: 4px;
  background-color: rgba(0, 0, 0, .3);
  &:hover {
    background-color: rgba(0, 0, 0, .5);
  }
}

.scroller-horizontal-handler {
  bottom: 1px;

  height: 8px;

  transition: opacity .3s;

  border-radius: 4px;
  background-color: rgba(0, 0, 0, .3);
  &:hover {
    background-color: rgba(0, 0, 0, .5);
  }
}

@link-color: #3370FF;@primary-color: #0039BF;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@grey-1: #fafafa;@grey-2: #f5f5f5;@grey-3: #f0f0f0;@grey-4: #d9d9d9;@grey-5: #bfbfbf;@grey-6: #8c8c8c;@grey-7: #595959;@grey-8: #434343;@grey-9: #262626;@grey-10: #1f1f1f;@border-radius-base: 4px;@border-color-base: #e9e9e9;@text-color: #646873;@text-color-secondary: #8F949E;